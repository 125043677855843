import {
  get, post, hapus, update, upload,
} from './helpers'

function getAllNotification(page, showAll) {
  return get(`/notification/list/${page}/${showAll}`)
}

function readNotification(id) {
  return post(`notification/read/${id}`)
}

/* eslint-disable import/prefer-default-export */
export {
  getAllNotification,
  readNotification,
}
