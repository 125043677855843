export default [
  {
    header: 'Pelaporan',
    resource: 'pelaporan',
    action: 'list',
  },
  {
    title: 'Laporan',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'Buat Pelaporan',
        route: 'penerimaan-create',
        // icon: 'FilePlusIcon',
        resource: 'penerimaan-create', 
        action: 'list',
      },
      {
        title: 'Online/Langsung',
        route: 'penerimaan-online',
        // icon: 'CloudIcon',
        resource: 'penerimaan-online', 
        action: 'list',
      },
      {
        title: 'Pos/Surat',
        route: 'penerimaan-pos',
        // icon: 'MailIcon',
        resource: 'penerimaan-pos', 
        action: 'list',
      },
      {
        title: 'Kantor Penghubung',
        route: 'penerimaan-kantor-penghubung',
        // icon: 'ArchiveIcon',
        resource: 'penerimaan-kantor-penghubung', 
        action: 'list',
      },
    ]
  },
  // {
  //   title: 'Kuasa Pelapor',
  //   route: 'kuasa-pelapor',
  //   icon: 'FileIcon',
  //   resource: 'kuasa-pelapor', 
  //   action: 'list',
  // },
  // {
  //   title: 'Terlapor',
  //   route: 'terlapor',
  //   icon: 'FileIcon',
  //   resource: 'terlapor', 
  //   action: 'list',
  // },
  {
    title: 'Distribusi Berkas',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'Monitoring',
        route: 'tim-monitoring',
        resource: 'tim',
        action: 'list',
      },
      {
        title: 'Manajemen Tim',
        route: 'tim',
        resource: 'tim',
        action: 'list',
      },
      {
        title: 'Distribusi Berkas',
        route: 'tim-distribusi-berkas',
        resource: 'tim',
        // action: 'distribusi-berkas', //note
        action: 'list',
      },
    ],
  },
  {
    title: 'Pendahuluan',
    icon: 'FilePlusIcon',
    children: [
      {
        title: 'Penerimaan',
        route: 'penerimaan',
        resource: 'penerimaan',
        action: 'list',
      },
      {
        title: 'Verifikasi Laporan',
        route: 'verifikasi',
        resource: 'verifikasi',
        action: 'list',
      },
      {
        title: 'Verifikasi Pending',
        route: 'verifikasi-pending',
        resource: 'verifikasi',
        action: 'list',
      },
      {
        title: 'Laporan Register',
        route: 'laporan-register',
        resource: 'laporan-register',
        action: 'list',
      },
    ],
  },
  // {
  //   title: 'Pemantauan',
  //   icon: 'VideoIcon',
  //   children: [
  //     {
  //       title: 'Monitoring',
  //       route: 'monitoring',
  //       resource: 'pemantauan',
  //       action: 'monitoring',
  //     },
  //     {
  //       title: 'Distribusi Berkas',
  //       route: 'distribusi-berkas',
  //       resource: 'distribusi-berkas',
  //       action: 'list',
  //     },
  //     {
  //       title: 'Pemantauan Inisiatif',
  //       route: 'pemantauan-inisiatif',
  //       resource: 'pemantauan-inisiatif',
  //       action: 'list',
  //     },
  //     {
  //       title: 'Pemantauan Permohonan',
  //       route: 'pemantauan-permohonan',
  //       resource: 'pemantauan-permohonan',
  //       action: 'list',
  //     },
  //   ],
  // },
  {
    title: 'Lanjutan',
    icon: 'UserCheckIcon',
    resource: 'tpl',
    action: 'list',
    children: [
      {
        title: 'Monitoring',
        route: 'tpl-monitoring',
        resource: 'tpl',
        // action: 'monitoring',
        action: 'list',
      },
      {
        title: 'Analisis Laporan',
        route: 'tpl-analisis-laporan',
        resource: 'tpl-analisis-laporan',
        action: 'list',
      },
      {
        title: 'Pemeriksaan Pendahuluan',
        route: 'tpl-pemeriksaan-pendahuluan',
        resource: 'tpl-pemeriksaan-pendahuluan',
        action: 'list',
      },
      {
        title: 'Pemeriksaan Lanjutan',
        route: 'tpl-pemeriksaan-lanjutan',
        resource: 'tpl-pemeriksaan-lanjutan',
        action: 'list',
      },
      {
        title: 'Jadwal Pemeriksaan',
        route: 'tpl-jadwal-pemeriksaan',
        resource: 'tpl-jadwal-pemeriksaan',
        action: 'list',
      },
    ],
  },
  {
    title: 'Persidangan',
    icon: 'PocketIcon',
    resource: 'monitoring',
    action: 'persidangan',
    children: [
      {
        title: 'Monitoring',
        route: 'persidangan-monitoring',
        resource: 'monitoring',
        action: 'persidangan',
        // action: 'list',
      },
      {
        title: 'Panel',
        route: 'panel',
        resource: 'panel',
        action: 'list',
        children: [
          {
            title: 'Daftar Tunggu',
            route: 'panel-daftar-tunggu',
            resource: 'panel',
            // action: 'daftar-tunggu',
            action: 'list',
          },
          {
            title: 'Jadwal',
            route: 'panel-jadwal',
            resource: 'panel',
            // action: 'jadwal',
            action: 'list',
          },
        ],
      },
      {
        title: 'Pleno',
        route: 'pleno',
        resource: 'pleno',
        action: 'list',
        children: [
          {
            title: 'Daftar Tunggu',
            route: 'pleno-daftar-tunggu',
            resource: 'pleno',
            // action: 'daftar-tunggu',
            action: 'list',
          },
          {
            title: 'Jadwal',
            route: 'pleno-jadwal',
            resource: 'pleno',
            // action: 'jadwal',
            action: 'list',
          },
          {
            title: 'Putusan Sidang Pleno',
            route: 'putusan-pleno',
            resource: 'putusan-pleno',
            action: 'list',
          },
          
        ],
      },
    ],
  },
  {
    icon: 'ArchiveIcon',
    title: 'MKH',
    route: 'mkh',
    resource: 'mkh',
    action: 'list',
  },
  {
    title: 'Arsip',
    icon: 'ArchiveIcon',
    route: 'dokumen-arsip',
    resource: 'dokumen-arsip',
    action: 'list',
  },
  {
    title: 'Rekapitulasi',
    icon: 'LayersIcon',
    children: [
      {
        title: 'Laporan Periodik',
        route: 'laporan-periodik',
        resource: 'laporan-periodik',
        icon: 'FileIcon',
        action: 'list',
      },
      {
        title: 'Laporan Bulanan',
        route: 'laporan-bulanan',
        resource: 'laporan-bulanan',
        icon: 'FileIcon',
        action: 'list',
      },
      // {
      //   title: 'Rekapitulasi',
      //   route: 'laporan-rekapitulasi',
      //   resource: 'laporan',
      //   // action: 'rekapitulasi',
      //   action: 'list',
      // },
      // {
      //   title: 'Rekapitulasi Penghubung',
      //   route: 'laporan-rekapitulasi-penghubung',
      //   resource: 'laporan',
      //   // action: 'rekapitulasi-penghubung',
      //   action: 'list',
      // },
      // {
      //   title: 'Rekapitulasi Penerimaan',
      //   route: 'laporan-rekapitulasi-penerimaan',
      //   resource: 'laporan',
      //   // action: 'rekapitulasi-penerimaan',
      //   action: 'list',
      // },
      // {
      //   title: 'Rekapitulasi Pemantauan',
      //   route: 'laporan-rekapitulasi-pemantauan',
      //   resource: 'laporan',
      //   // action: 'rekapitulasi-pemantauan',
      //   action: 'list',
      // },
      // {
      //   title: 'Monitoring Persidangan',
      //   route: 'laporan-monitoring-persidangan',
      //   resource: 'laporan',
      //   // action: 'monitoring-persidangan',
      //   action: 'list',
      // },
      // {
      //   title: 'Usul Saksi',
      //   route: 'laporan-usul-saksi',
      //   resource: 'laporan',
      //   // action: 'usul-saksi',
      //   action: 'list',
      // },
      // {
      //   title: 'Data Integritas',
      //   route: 'laporan-data-integritas',
      //   resource: 'laporan',
      //   // action: 'data-integritas',
      //   action: 'list',
      // },
    ],
  },
  // {
  //   header: 'Investigasi',
  //   resource: 'investigasi',
  //   action: 'list',
  // },
  // {
  //   title: 'Investigasi dan Advokasi',
  //   route: 'investigasi-advokasi',
  //   resource: 'investigasi-advokasi',
  //   action: 'list',
  //   icon: 'SearchIcon',
  // },
  {
    header: 'Manajemen Sistem',
    resource: 'sistem',
    action: 'list',
  },
  // {
  //   title: 'Dokumen',
  //   icon: 'FolderIcon',
  //   children: [
  //     {
  //       title: 'e-Monitoring',
  //       route: 'dokumen-emonitoring',
  //       resource: 'dokumen-emonitoring',
  //       action: 'list',
  //     },
  //     {
  //       title: 'e-Laporan',
  //       route: 'dokumen-elaporan',
  //       resource: 'dokumen-elaporan',
  //       action: 'list',
  //     },
  //     {
  //       title: 'e-Verifikasi',
  //       route: 'dokumen-everifikasi',
  //       resource: 'dokumen-evirifikasi',
  //       action: 'list',
  //     },
  //   ],
  // },
  {
    title: 'Akses',
    icon: 'UserCheckIcon',
    children: [
      {
        title: 'Masyarakat',
        route: { name: 'pendaftar'},
        resource: 'user',
        // action: 'masyarakat',
        action: 'list',
      },
      {
        title: 'Kantor Penghubung',
        route: { name: 'penghubung' },
        resource: 'user',
        // action: 'penghubung',
        action: 'list',
      },
      {
        title: 'Pengguna',
        route: 'pengguna-list',
        resource: 'user',
        // action: 'pengguna',
        action: 'list',
      },
      {
        title: 'Hak Akses',
        route: 'pengguna-rules-list',
        resource: 'user',
        // action: 'roles',
        action: 'list',
      },
    ],
  },
  
  {
    title: 'Referensi',
    icon: 'CheckSquareIcon',
    children: [
      {
        title: 'Agama',
        route: 'agama',

        action: 'list',
      },
      {
        title: 'Kantor Penghubung',
        route: 'kantor-penghubung',

        action: 'list',
      },
      {
        title: 'Badan Peradilan',
        route: 'badan-peradilan',

        action: 'list',
      },
      {
        title: 'Status Pelaporan',
        route: 'status-pelaporan',

        action: 'list',
      },
      {
        title: 'Status Berkas',
        route: 'status-berkas',

        action: 'list',
      },
      {
        title: 'Status Pemantauan Laporan',
        route: 'status-pemantauan',

        action: 'list',
      },
      {
        title: 'Daftar Tim',
        route: 'daftar-tim',

        action: 'list',
      },
      {
        title: 'Dugaan Pelanggaran',
        route: 'dugaan-pelanggaran',

        action: 'list',
      },
      {
        title: 'Jenis Dokumen',
        route: 'jenis-dokumen',

        action: 'list',
      },
      {
        title: 'Jenis Dokumen MKH',
        route: 'jenis-dokumen-mkh',

        action: 'list',
      },
      {
        title: 'Jenis Instrumen',
        route: 'jenis-instrumen',

        action: 'list',
      },
      {
        title: 'Jenis Kelamin',
        route: 'jenis-kelamin',

        action: 'list',
      },
      {
        title: 'Jenis Kelengkapan',
        route: 'jenis-kelengkapan',

        action: 'list',
      },
      {
        title: 'Jenis Laporan',
        route: 'jenis-laporan',

        action: 'list',
      },
      {
        title: 'Jenis Pengadilan',
        route: 'jenis-pengadilan',

        action: 'list',
      },
      {
        title: 'Jenis Tindakan Pemantauan',
        route: 'jenis-tindakan-pemantauan',

        action: 'list',
      },
      {
        title: 'Jenis Perkara',
        route: 'jenis-perkara',

        action: 'list',
      },
      {
        title: 'Jenis Penyampaian',
        route: 'jenis-penyampaian',

        action: 'list',
      },
      {
        title: 'Jenis Pekerjaan',
        route: 'jenis-pekerjaan',

        action: 'list',
      },
      {
        title: 'Lingkungan Peradilan',
        route: 'lingkungan-peradilan',

        action: 'list',
      },
      // {
      //   title: 'Jangka Waktu',
      //   route: 'jangka_waktu',
      // },
      // {
      //   title: 'Sumber Dana',
      //   route: 'sumber_dana',
      // },
      // {
      //   title: 'Klasifikasi Mitra',
      //   route: 'klasifikasi_mitra',
      // },
      // {
      //   title: 'Minutasi',
      //   route: 'minutasi',
      // },
      {
        title: 'Negara',
        route: 'negara',

        action: 'list',
      },
      {
        title: 'Provinsi',
        route: 'provinsi',

        action: 'list',
      },
      {
        title: 'Kab/Kota',
        route: 'kota',

        action: 'list',
      },
      {
        title: 'Jenis Surat',
        route: 'jenis-surat',

        action: 'list',
      },
      {
        title: 'Status Investigasi Advokasi',
        route: 'status-investigasi-advokasi',

        action: 'list',
      },
      {
        title: 'Template',
        route: 'template',

        action: 'list',

      },
    ],
  },
  {
    header: 'Lainnya',
    resource: 'distribusi-berkas',
    action: 'list',
  },
  {
    title: 'Bantuan',
    route: 'pages-faq',
    icon: 'HelpCircleIcon',
    resource: 'faq', 
    action: 'list',
  },
  {
    title: 'Pesan',
    route: 'apps-chat',
    icon: 'MessageSquareIcon',
    resource: 'chat',
    action: 'list',
  },
  // {
  //   title: 'Data Master',
  //   icon: 'DatabaseIcon',
  //   children: [
  //     {
  //       title: 'Jenis Dokumen',
  //       route: 'jenis_dokumen',
  //     },
  //     {
  //       title: 'Unit Utama',
  //       route: 'unit_utama',
  //     },
  //     {
  //       title: 'Instansi/Mitra',
  //       route: 'instansi',
  //     },
  //   ],
  // },
  // {
  //   title: 'Pengaturan',
  //   icon: 'SettingsIcon',
  //   children: [
  //     {
  //       title: 'Konfigurasi',
  //       route: 'apps-invoice-list',
  //     },
  //     {
  //       title: 'Authentifikasi',
  //       route: { name: 'apps-invoice-preview', params: { id: 4987 } },
  //     },
  //     {
  //       title: 'Google API',
  //       route: { name: 'apps-invoice-edit', params: { id: 4987 } },
  //     },
  //     {
  //       title: 'Mailer',
  //       route: { name: 'apps-invoice-edit', params: { id: 4987 } },
  //     },
  //     {
  //       title: 'API',
  //       route: { name: 'apps-invoice-edit', params: { id: 4987 } },
  //     },
  //     {
  //       title: 'Repository',
  //       route: { name: 'apps-invoice-edit', params: { id: 4987 } },
  //     },
  //   ],
  // },
  // {
  //   header: 'Lainnya',
  //   resource: 'distribusi-berkas',
  //   action: 'list',
  // },
  // {
  //   title: 'Bantuan',
  //   route: 'faq',
  //   icon: 'HelpCircleIcon',
  //   resource: 'bantuan',
  //   action: 'list',
  // },
  // {
  //   title: 'Pesan',
  //   route: 'pesan',
  //   icon: 'MessageSquareIcon',
  //   resource: 'pesan',
  //   action: 'list',
  // },
  // {
  //   header: 'Apps & Pages',
  // },
  // {
  //   title: 'Email',
  //   route: 'apps-email',
  //   icon: 'MailIcon',
  // },
  // {
  //   header: 'Apps & Pages',
  // },
  // {
  //   title: 'Email',
  //   route: 'apps-email',
  //   icon: 'MailIcon',
  // },
  // {
  //   title: 'Chat',
  //   route: 'apps-chat',
  //   icon: 'MessageSquareIcon',
  // },
  // {
  //   title: 'Todo',
  //   route: 'apps-todo',
  //   icon: 'CheckSquareIcon',
  // },
  // {
  //   title: 'Calendar',
  //   route: 'apps-calendar',
  //   icon: 'CalendarIcon',
  // },
  // {
  //   title: 'Invoice',
  //   icon: 'FileTextIcon',
  //   children: [
  //     {
  //       title: 'List',
  //       route: 'apps-invoice-list',
  //     },
  //     {
  //       title: 'Preview',
  //       route: { name: 'apps-invoice-preview', params: { id: 4987 } },
  //     },
  //     {
  //       title: 'Edit',
  //       route: { name: 'apps-invoice-edit', params: { id: 4987 } },
  //     },
  //     {
  //       title: 'Add',
  //       route: { name: 'apps-invoice-add' },
  //     },
  //   ],
  // },
//   {
//     title: 'eCommerce',
//     icon: 'ShoppingCartIcon',
//     children: [
//       {
//         title: 'Shop',
//         route: 'apps-e-commerce-shop',
//       },
//       {
//         title: 'Details',
//         route: { name: 'apps-e-commerce-product-details', params: { slug: 'apple-watch-series-5-27' } },
//       },
//       {
//         title: 'Wishlist',
//         route: 'apps-e-commerce-wishlist',
//       },
//       {
//         title: 'Checkout',
//         route: 'apps-e-commerce-checkout',
//       },
//     ],
//   },
//   {
//     title: 'User',
//     icon: 'UserIcon',
//     children: [
//       {
//         title: 'List',
//         route: 'apps-users-list',
//       },
//       {
//         title: 'View',
//         route: { name: 'apps-users-view', params: { id: 21 } },
//       },
//       {
//         title: 'Edit',
//         route: { name: 'apps-users-edit', params: { id: 21 } },
//       },
//     ],
//   },
//   {
//     title: 'Pages',
//     icon: 'FileIcon',
//     children: [
//       {
//         title: 'Authentication',
//         icon: 'CircleIcon',
//         children: [
//           {
//             title: 'Login v1',
//             route: 'auth-login-v1',
//             target: '_blank',
//           },
//           {
//             title: 'Login v2',
//             route: 'auth-login-v2',
//             target: '_blank',
//           },
//           {
//             title: 'Register v1',
//             route: 'auth-register-v1',
//             target: '_blank',
//           },
//           {
//             title: 'Register v2',
//             route: 'auth-register-v2',
//             target: '_blank',
//           },
//           {
//             title: 'Forgot Password v1',
//             route: 'auth-forgot-password-v1',
//             target: '_blank',
//           },
//           {
//             title: 'Forgot Password v2',
//             route: 'auth-forgot-password-v2',
//             target: '_blank',
//           },
//           {
//             title: 'Reset Password v1',
//             route: 'auth-reset-password-v1',
//             target: '_blank',
//           },
//           {
//             title: 'Reset Password v2',
//             route: 'auth-reset-password-v2',
//             target: '_blank',
//           },
//         ],
//       },
//       {
//         title: 'Account Settings',
//         route: 'pages-account-setting',
//       },
//       {
//         title: 'Profile',
//         route: 'pages-profile',
//       },
//       {
//         title: 'Faq',
//         route: 'pages-faq',
//       },
//       {
//         title: 'Knowledge Base',
//         route: 'pages-knowledge-base',
//       },
//       {
//         title: 'Pricing',
//         route: 'pages-pricing',
//       },
//       {
//         title: 'Blog',
//         children: [
//           {
//             title: 'List',
//             route: 'pages-blog-list',
//           },
//           {
//             title: 'Detail',
//             route: { name: 'pages-blog-detail', params: { id: 1 } },
//           },
//           {
//             title: 'Edit',
//             route: { name: 'pages-blog-edit', params: { id: 1 } },
//           },
//         ],
//       },
//       {
//         title: 'Mail Templates',
//         children: [
//           {
//             title: 'Welcome',
//             href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-welcome.html',
//           },
//           {
//             title: 'Reset Password',
//             href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-reset-password.html',
//           },
//           {
//             title: 'Verify Email',
//             href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-verify-email.html',
//           },
//           {
//             title: 'Deactivate Account',
//             href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-deactivate-account.html',
//           },
//           {
//             title: 'Invoice',
//             href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-invoice.html',
//           },
//           {
//             title: 'Promotional',
//             href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-promotional.html',
//           },
//         ],
//       },
//       {
//         title: 'Miscellaneous',
//         icon: 'CircleIcon',
//         children: [
//           {
//             title: 'Coming Soon',
//             route: 'misc-coming-soon',
//             target: '_blank',
//           },
//           {
//             title: 'Not Authorized',
//             route: 'misc-not-authorized',
//             target: '_blank',
//           },
//           {
//             title: 'Under Maintenance',
//             route: 'misc-under-maintenance',
//             target: '_blank',
//           },
//           {
//             title: 'Error',
//             route: 'misc-error',
//             target: '_blank',
//           },
//         ],
//       },
//     ],
//   },
 ]
