export default [
  {
    // title: 'Dashboards',
    // icon: 'HomeIcon',
    // tag: '2',
    // tagVariant: 'light-warning',
    // children: [
    //   {
    //     title: 'eCommerce',
    //     route: 'dashboard-ecommerce',
    //   },
    //   {
    //     title: 'Analytics',
    //     route: 'dashboard-analytics',
    //   },
    // ],
      title: 'Beranda',
      route: 'dashboard-analytics',
      icon: 'HomeIcon',
      resource: 'dashboard',
      action: 'analytics',
      // tag: '2',
      // tagVariant: 'light-warning',
  },
  {
    title: 'Monitoring',
    route: 'dashboard-monitoring',
    icon: 'EyeIcon',
    resource: 'pelaporan',
    action: 'monitoring',
  }
]
